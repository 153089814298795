import { formatNumberWithCommas, pluralize } from './format';
import {
  BdMigrationActivity,
  BdMigrationDetails,
  Patient,
  PatientActivity,
  PatientTransaction,
  PatientTransactionDisplayLineItem,
  PatientTransactionStatus,
  PointsCapError,
} from '@packages/core-alle-graphql-types';
import { SummaryDataItems } from '@packages/deprecated-shared-sdk/src/customTypes/customTypes';
import { FinancingData } from '../views/treatments-history/helpers/getFinancingData';

export interface TableItem {
  name: string;
  value: string;
  includeTooltip?: boolean;
}

export interface TableChunk {
  title?: string;
  data: Array<TableItem | null>;
}

export type SimpleTableData = Array<TableChunk>;

const getLineItemName = (name: string, status?: PatientTransactionStatus) => {
  if (status === PatientTransactionStatus.Cancelled) {
    if (name === 'Points Earned') {
      return 'Points Cancelled';
    } else if (name === 'Savings Applied') {
      return 'Savings Cancelled';
    }
    return name;
  }
  return name;
};

const mapDisplayLine = (
  value: PatientTransactionDisplayLineItem,
  status?: PatientTransactionStatus
) => {
  const renderedValue = value.value || '';
  const name = getLineItemName(value.name, status);
  return {
    name,
    value: renderedValue
      ? formatNumberWithCommas(renderedValue)
      : renderedValue,
  };
};

const getConnectionData = (value: any, status?: PatientTransactionStatus) =>
  value?.displayLineItems
    ?.filter(
      (dl: PatientTransactionDisplayLineItem) =>
        dl?.name !== SummaryDataItems.ID
    )
    ?.map((dl: PatientTransactionDisplayLineItem) =>
      mapDisplayLine(dl, status)
    ) || [];

const mapProductEdge = (edge: any) => {
  const name = edge?.node?.displayName || edge?.node?.name || '';
  const points = edge?.points || 0;
  const value = `${formatNumberWithCommas(points)} ${
    points === 1 ? 'Point' : 'Points'
  }`;
  return {
    name,
    value,
  };
};

const getProductData = (value: any) => value?.edges?.map(mapProductEdge) || [];

const formatMigrationDetails = ({
  hasOYLOffer,
  bdPoints,
  bdCouponDollars,
  bdCouponPoints,
  trackerDollars,
  trackerPoints,
}: BdMigrationDetails) => {
  const totalRewardsMigrated = hasOYLOffer ? 1 : 0;
  const accountMigrationData = [
    {
      name: `${formatNumberWithCommas(
        bdPoints
      )} Brilliant Distinctions® ${pluralize('Point', bdPoints)}`,
      value: `${formatNumberWithCommas(bdPoints)} ${pluralize(
        'point',
        bdPoints
      )}`,
    },
    {
      name: `$${formatNumberWithCommas(
        bdCouponDollars
      )} Brilliant Distinctions® Coupons`,
      value: `${formatNumberWithCommas(bdCouponPoints)} ${pluralize(
        'point',
        bdCouponPoints
      )}`,
    },
    {
      name: `$${formatNumberWithCommas(
        trackerDollars
      )} Latisse® (bimatoprost ophthalmic solution) 0.03% / SkinMedica® Purchases`,
      value: `${formatNumberWithCommas(trackerPoints)} ${pluralize(
        'point',
        trackerPoints
      )}`,
    },
  ];
  if (hasOYLOffer) {
    accountMigrationData.push({
      name: `OYL Complimentary Treatment`,
      value: `1 reward`,
    });
  }
  const totalPointsMigrated = bdPoints + bdCouponPoints + trackerPoints;
  const summaryData = [
    null,
    {
      name: `Total Points Migrated`,
      value: `${formatNumberWithCommas(totalPointsMigrated)} ${pluralize(
        'point',
        totalPointsMigrated
      )}`,
    },
    {
      name: `Total Rewards Migrated`,
      value: `${formatNumberWithCommas(totalRewardsMigrated)} ${pluralize(
        'reward',
        totalRewardsMigrated
      )}`,
    },
  ];
  return [
    {
      title: `Brilliant Distinctions® Account Migration`,
      data: accountMigrationData,
    },
    { title: '', data: [] },
    {
      title: `Summary`,
      data: summaryData,
    },
  ];
};

const getMigrationFromBdActivities = (
  bdMigrationActivity: BdMigrationActivity | undefined | null
) => {
  const {
    bdPoints = 0,
    bdCouponDollars = 0,
    bdCouponPoints = 0,
    trackerDollars = 0,
    trackerPoints = 0,
    hasOYLOffer,
  } = bdMigrationActivity?.migrationDetails || {};

  return formatMigrationDetails({
    bdPoints,
    bdCouponDollars,
    bdCouponPoints,
    trackerDollars,
    trackerPoints,
    hasOYLOffer,
  });
};

const getMigrationActivityDisplayData = (
  patient?: Patient
): SimpleTableData => {
  const {
    bdPoints = 0,
    bdCouponDollars = 0,
    bdCouponPoints = 0,
    trackerDollars = 0,
    trackerPoints = 0,
    hasOYLOffer,
  } = patient?.bdActivitiesConnection?.migrationActivity?.migrationDetails ||
  {};

  return formatMigrationDetails({
    bdPoints,
    bdCouponDollars,
    bdCouponPoints,
    trackerDollars,
    trackerPoints,
    hasOYLOffer,
  });
};

const getPointsCapErrorProductUnit = (error: PointsCapError) => {
  return error.product.unit
    ? error.quantity === 1
      ? ` ${error.product.unit?.single}`
      : ` ${error.product.unit?.plural}`
    : '';
};

export interface MigrationData {
  migrationActivityData: SimpleTableData;
  migrationDate?: string;
  migrationDateSortable?: string;
}

export type PatientTransactionOrMigration = PatientTransaction | MigrationData;

const isPatientTransaction = (
  obj?: PatientActivity | null
): obj is PatientTransaction => {
  return obj?.__typename === 'PatientTransaction';
};

const isMigration = (
  obj?: PatientActivity | null
): obj is BdMigrationActivity => {
  return obj?.__typename === 'BDMigrationActivity';
};

const mergeMigrationDataWithTransactions = (
  patient: Patient | undefined,
  mergeEnabled: boolean = true
): Array<PatientTransactionOrMigration> => {
  const maybePatientTransactions =
    patient?.transactionsConnection?.transactions || [];
  const patientTransactions = maybePatientTransactions.filter(
    (tx) => !!tx
  ) as Array<PatientTransaction>;
  const patientMigrated = !!patient?.bdActivitiesConnection?.migrationActivity;
  if (patientMigrated && mergeEnabled) {
    // @ts-ignore
    return [
      ...patientTransactions,
      {
        migrationActivityData: getMigrationActivityDisplayData(patient),
        migrationDate:
          patient?.bdActivitiesConnection?.migrationActivity?.createdAt
            ?.format!,
        // @ts-ignore sortable fields are aliases so we don't have the type defined
        migrationDateSortable: patient?.bdActivitiesConnection?.migrationActivity?.createdAtSortable?.format!, // prettier-ignore
      },
    ].sort((a, b) => {
      // @ts-ignore sortable fields are aliases so we don't have the type defined
      const aDate = new Date(a?.migrationDateSortable || a?.transactionDateSortable?.format); // prettier-ignore
      // @ts-ignore sortable fields are aliases so we don't have the type defined
      const bDate = new Date(b?.migrationDateSortable || b?.transactionDateSortable?.format); // prettier-ignore
      return bDate.getTime() - aDate.getTime();
    });
    /* eslint-enable */
  }
  // @ts-ignore
  return patientTransactions;
};

const getFormattedFinancingData = (financingData: FinancingData[]) => {
  return financingData.length > 0
    ? [
        {
          name: 'Financed Amount',
          value: financingData[0].value,
        },
      ]
    : [];
};

const isDefined = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

export {
  mergeMigrationDataWithTransactions,
  getConnectionData,
  getLineItemName,
  getProductData,
  getMigrationFromBdActivities,
  getPointsCapErrorProductUnit,
  isPatientTransaction,
  isMigration,
  getFormattedFinancingData,
  isDefined,
};
