import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import useScrollPosition from '@react-hook/window-scroll';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import { Flex, Box } from '@allergan-data-labs/alle-elements-layout';
import { Button, ButtonGroup } from '@allergan-data-labs/alle-elements-button';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { NavigationItem } from './headerDrawerItem';
import { useHeaderTrackingEvents } from './useHeaderTrackingEvents';
import { useHeaderNavLinks } from './utils';

const initialDrawerItemsState: Record<string, boolean> = {
  brands: false,
  rewards: false,
};

type HeaderDrawerProps = {
  isOpen?: boolean;
  isLoggedIn?: boolean;
  relative?: boolean;
  toggleDrawer: () => void;
  getLoginLinkProps: () => {
    href: string;
    referrerData?: { from: string | object };
  };
  getJoinLinkProps: () => { href: string };
};

const HeaderDrawer = ({
  isOpen,
  isLoggedIn,
  relative,
  toggleDrawer,
  getLoginLinkProps,
  getJoinLinkProps,
}: HeaderDrawerProps) => {
  const { location } = useHistory();
  const { Link } = useEnvironmentContext();
  const { trackLoginLinkClick, trackJoinAlleClick } = useHeaderTrackingEvents();

  const scrollY = useScrollPosition();

  const { links } = useHeaderNavLinks({
    isLoggedIn,
    currentPath: location.pathname,
  });

  const [drawerItemsState, setDrawerItemsState] = useState(
    initialDrawerItemsState
  );

  const toggleNavigationItem = (id: string) => {
    setDrawerItemsState({
      ...drawerItemsState,
      [id]: !drawerItemsState[id],
    });
  };

  const topPosition = useMemo(() => {
    return relative ? `${64 - scrollY}px` : '64px';
  }, [relative, scrollY]);

  return (
    <Box zIndex={1}>
      <Drawer
        isOpen={!!isOpen}
        placement="left"
        onClose={toggleDrawer}
        isFullHeight={false}
      >
        <DrawerOverlay top={topPosition} />
        <DrawerContent
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          backgroundColor={`light.Section bg/Neutral/Mute 1`}
          marginTop={topPosition}
          padding={16}
          paddingBottom={0}
          boxSizing="border-box"
          maxWidth={{
            base: '100vw',
            sm: 360,
          }}
        >
          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap={16}
            paddingTop={0}
            paddingX={0}
            paddingBottom={16}
          >
            <Flex flexDirection="column" gap={8}>
              {links.map((item) => (
                <NavigationItem
                  key={item.id}
                  item={item}
                  isNestedOpen={drawerItemsState[item.id as string]}
                  handleNestedToggle={() =>
                    toggleNavigationItem(item.id as string)
                  }
                  handleItemClick={toggleDrawer}
                />
              ))}
            </Flex>
            {!isLoggedIn && (
              <ButtonGroup
                alignItems="center"
                gap={8}
                marginTop={'auto'}
                width="100%"
              >
                <Link
                  onClick={trackLoginLinkClick}
                  {...getLoginLinkProps()}
                  flex={1}
                >
                  <Button size="sm" variant="outline" width="100%">
                    Log In
                  </Button>
                </Link>
                <Link
                  onClick={trackJoinAlleClick}
                  {...getJoinLinkProps()}
                  flex={1}
                  marginInlineStart={`0 !important`}
                >
                  <Button size="sm" width="100%">
                    Join Allē
                  </Button>
                </Link>
              </ButtonGroup>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export { HeaderDrawer };
