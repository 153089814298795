import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Slide } from '@chakra-ui/react';
import { useMachine } from '@xstate/react';

import { Avatar } from '@allergan-data-labs/alle-elements-avatar';
import {
  Button,
  ButtonGroup,
  IconButton,
} from '@allergan-data-labs/alle-elements-button';
import { useBreakpoint } from '@allergan-data-labs/alle-elements-core';
import { HamburgerActiveIcon } from '@allergan-data-labs/alle-elements-icons/24/hamburgerActiveIcon';
import { HamburgerInactiveIcon } from '@allergan-data-labs/alle-elements-icons/24/hamburgerInactiveIcon';
import { Box, Flex } from '@allergan-data-labs/alle-elements-layout';

import {
  ConsumerProfile,
  Consumer_Tier,
  LoyaltyPoints,
} from '@packages/core-alle-graphql-types';
import { useEnvironmentContext } from '@packages/core-components/src/components/environment-context/environmentContext';
import { useScrollTrigger } from '@packages/core-components/src/hooks/ui/useScrollTrigger';
import { AlleLogo } from '@packages/deprecated-component-library/src/icons/alleLogo';

import { pointsToPercentage } from '@packages/deprecated-universal-component-library/src/helpers/pointsToPercentage';

import { AuthState } from '../../auth/authMachine';
import { useAuth } from '../../auth/AuthProvider';
import { createDrawerStateMachine } from '../../components/drawer/drawerStateMachine';
import { Routes } from '../../constants/routes';
import { useProfileRedesignFeature } from '../../hooks/useProfileRedesignFeature';
import { AlistTrackerDialog } from '../../components/drawer/myProfile/tier/alistTrackerDialog';
import { HeaderDrawer } from './headerDrawer';
import { HeaderProfileModal } from './headerProfileModal';
import { HeaderSelect } from './headerSelect';
import { useHeaderColors } from './useHeaderColors';
import { useHeaderTrackingEvents } from './useHeaderTrackingEvents';
import { useHeaderNavLinks } from './utils';
import { getAListTierProgressType } from '../../components/drawer/myProfile/tier/alistTier/alistHelpers';
import { ConsumerTierAListTypes } from '../../components/drawer/myProfile/tier/alistTier/alistTypes';

const ProfileDrawer = React.lazy(() =>
  import(
    /* webpackChunkName: 'Drawer' */ '../../components/drawer/drawer'
  ).then((mod) => ({
    default: mod.Drawer,
  }))
);

const Header: React.FunctionComponent<{
  keepPathOnLogin?: boolean;
  jumpLink?: string; // Only used in one preview route (Routes.articlePreview, see routeConfig)
  relative?: boolean;
}> = ({ keepPathOnLogin, jumpLink, relative }) => {
  const {
    currentUser,
    authState,
    consumerProfileLoading,
    consumerProfileError,
  } = useAuth();
  const isLoggedIn = !!currentUser || authState.matches(AuthState.isLoggedIn);
  const isProfileRedesignEnabled = useProfileRedesignFeature();

  const machine = useMemo(createDrawerStateMachine, []);
  const [current, send] = useMachine(machine);

  const history = useHistory();
  const location = history.location;
  const currentPath = location.pathname;
  const { Link } = useEnvironmentContext();
  const { links } = useHeaderNavLinks({
    isLoggedIn,
    currentPath,
  });

  const trigger = useScrollTrigger();
  const { hideNavOnBreakpoint } = useBreakpoint<{
    hideNavOnBreakpoint?: boolean;
  }>({
    base: { hideNavOnBreakpoint: false },
    xs: { hideNavOnBreakpoint: false },
    sm: { hideNavOnBreakpoint: false },
    md: { hideNavOnBreakpoint: false },
    lg: { hideNavOnBreakpoint: true },
    xl: { hideNavOnBreakpoint: true },
    '2xl': { hideNavOnBreakpoint: true },
  });

  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isAListDialogOpen, setIsAListDialogOpen] = useState(false);

  const {
    bgColor,
    borderColor,
    menuIconColor,
    navLinkColor,
    navLinkHoverColor,
    isLogoDark,
  } = useHeaderColors({
    isLoggedIn,
  });
  const {
    trackAlleLogoClick,
    trackLoginLinkClick,
    trackJoinAlleClick,
    trackProfileAvatarClick,
    trackHamburgerMenuClick,
    trackHeaderLinkClick,
  } = useHeaderTrackingEvents();

  useEffect(() => {
    if (hideNavOnBreakpoint && isNavDrawerOpen) {
      setIsNavDrawerOpen(false);
    }
  }, [hideNavOnBreakpoint, isNavDrawerOpen]);

  const toggleNavDrawer = () => {
    setIsNavDrawerOpen(!isNavDrawerOpen);
  };

  const onMenuIconClick = () => {
    trackHamburgerMenuClick?.();
    toggleNavDrawer();
  };

  const getLoginLinkProps = () => {
    if (keepPathOnLogin) {
      return {
        href: Routes.login,
        referrerData: {
          from: { pathname: location.pathname, search: location.search || '' },
        },
      };
    }
    return {
      href: Routes.login,
      referrerData: { from: { search: location.search || '' } },
    };
  };

  const getJoinLinkProps = () => {
    if (jumpLink) {
      return { href: `${location.pathname}${jumpLink}` };
    } else {
      return { href: Routes.registrationPhoneNumber };
    }
  };

  const handleProfileAvatarClick = () => {
    send('TOGGLE_DRAWER');
    trackProfileAvatarClick();
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const computedTierPoints = pointsToPercentage(
    currentUser?.loyalty?.points?.tierPoints,
    currentUser?.loyalty?.points?.tierThreshold
  );
  const isAList = currentUser?.loyalty?.tier === Consumer_Tier.AList;

  return (
    <>
      <Slide
        direction="top"
        in={relative ? true : !trigger}
        style={{ zIndex: 10, position: relative ? 'relative' : 'fixed' }}
        motionProps={{
          initial: false,
        }}
      >
        <Box
          width="100%"
          data-testid={isLoggedIn ? 'logged-in-header' : 'logged-out-header'}
        >
          <Flex
            padding={{ base: '12px 16px 12px 8px', md: '12px 16px' }}
            justifyContent="space-between"
            alignItems="center"
            height={64}
            boxSizing="border-box"
            borderBottom={`1px solid`}
            borderColor={borderColor}
            background={bgColor}
          >
            <Flex gap={8} alignItems="center">
              <Box
                display={{
                  base: 'block',
                  md: 'none',
                }}
              >
                <IconButton
                  onClick={onMenuIconClick}
                  variant="ghost"
                  data-testid="open-drawer-button"
                  aria-label="Menu button"
                  height="42px"
                  minWidth="42px"
                  icon={
                    isNavDrawerOpen ? (
                      <HamburgerActiveIcon color={menuIconColor} />
                    ) : (
                      <HamburgerInactiveIcon color={menuIconColor} />
                    )
                  }
                  // Passing dimensions directly to the icon element above didn't work
                  sx={{ svg: { height: '28px', width: '28px' } }}
                />
              </Box>
              <Flex alignItems="center" gap={24}>
                <Box
                  sx={{
                    '*': {
                      lineHeight: 0,
                    },
                    svg: {
                      height: '24px !important',
                      width: '50px !important',
                    },
                  }}
                >
                  <Link
                    aria-label="ALLE"
                    href={Routes.home}
                    onClick={trackAlleLogoClick}
                  >
                    <AlleLogo dark={isLogoDark} />
                  </Link>
                </Box>
                <Flex
                  data-testid="header-desktop-links"
                  alignItems="center"
                  gap={24}
                  display={{
                    base: 'none',
                    md: 'flex',
                  }}
                >
                  {links.map((link) => {
                    if (link.items) {
                      return (
                        <HeaderSelect
                          key={`${link.id}-${currentPath}`}
                          id={link.id}
                          data-testid={`header-nav-${link.id}`}
                          isLoggedIn={isLoggedIn}
                          hideOnScroll={trigger}
                          {...link}
                        />
                      );
                    }

                    return (
                      <Link
                        key={link.id}
                        data-testid={`header-nav-${link.id}`}
                        href={link.href}
                        fontSize="14px"
                        fontWeight={link.isActive ? '600' : '400'}
                        isStandalone
                        variant="action"
                        color={navLinkColor}
                        _hover={{
                          textDecoration: 'none',
                          color: navLinkHoverColor,
                        }}
                        onClick={() =>
                          trackHeaderLinkClick({
                            title: link.title || '',
                            url: link.href,
                          })
                        }
                      >
                        {link.title}
                      </Link>
                    );
                  })}
                </Flex>
              </Flex>
            </Flex>
            <Box>
              {isLoggedIn ? (
                <Avatar
                  data-testid="open-menu-button"
                  name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                  borderColor="transparent"
                  onClick={handleProfileAvatarClick}
                  cursor="pointer"
                  // To avoid showing weird initials when user info hasn't loaded
                  getInitials={!currentUser ? () => '' : undefined}
                  backgroundColor={isAList ? '#1C1B1B' : undefined}
                  foregroundColor={isAList ? '#C3A45E' : undefined}
                />
              ) : (
                <ButtonGroup
                  alignItems="center"
                  gap={8}
                  display={isNavDrawerOpen ? 'none' : 'flex'}
                >
                  <Link
                    data-testid="header-login-link"
                    fontSize="14px"
                    isStandalone
                    variant="action"
                    onClick={trackLoginLinkClick}
                    {...getLoginLinkProps()}
                    _hover={{
                      textDecoration: 'none',
                      color: navLinkHoverColor,
                    }}
                  >
                    Log In
                  </Link>
                  <Link
                    onClick={trackJoinAlleClick}
                    {...getJoinLinkProps()}
                    data-testid="header-join-link"
                  >
                    <Button size="sm" width={95}>
                      Join Allē
                    </Button>
                  </Link>
                </ButtonGroup>
              )}
            </Box>
          </Flex>
          <HeaderDrawer
            isOpen={!isProfileRedesignEnabled && isNavDrawerOpen}
            isLoggedIn={isLoggedIn}
            relative={relative}
            toggleDrawer={toggleNavDrawer}
            getLoginLinkProps={getLoginLinkProps}
            getJoinLinkProps={getJoinLinkProps}
          />
          <HeaderProfileModal
            profile={currentUser as Partial<ConsumerProfile>}
            isOpen={isProfileRedesignEnabled && isProfileModalOpen}
            onClose={() => setIsProfileModalOpen(false)}
            onOpenAListDialog={() => {
              setIsProfileModalOpen(false);
              setIsAListDialogOpen(true);
            }}
          />
        </Box>
      </Slide>
      {/* Profile Drawer */}
      <React.Suspense fallback={null}>
        <ProfileDrawer
          open={!isProfileRedesignEnabled && current.matches('open')}
          onClose={() => send('TOGGLE_DRAWER')}
          current={current}
          send={send}
        />
      </React.Suspense>
      <React.Suspense fallback={null}>
        <AlistTrackerDialog
          computedTierPoints={computedTierPoints}
          points={currentUser?.loyalty?.points?.tierPoints}
          tierThreshold={currentUser?.loyalty?.points?.tierThreshold}
          type={
            currentUser?.loyalty?.points
              ? getAListTierProgressType(
                  currentUser?.loyalty?.points as LoyaltyPoints
                )
              : ConsumerTierAListTypes.Member
          }
          open={isAListDialogOpen}
          onClose={() => setIsAListDialogOpen(false)}
          error={consumerProfileError !== undefined}
          loading={consumerProfileLoading}
        />
      </React.Suspense>
    </>
  );
};

export { Header };
