import React from 'react';
import { Collapse, Stack, VStack } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/react';
import {
  NavigationDrawerLinkItem,
  NavigationDrawerLinkItemProps,
} from './headerDrawerLink';
import { NavigationDrawerButton } from './headerDrawerButton';

const getNavigationItemStackStyles = (): SystemStyleObject => {
  return {
    gap: 8,
  };
};

export const getNavigationItemNestedStyles = (): SystemStyleObject => {
  return {
    a: {
      pl: 24,
    },
    ...getNavigationItemStackStyles,
  };
};

export const getNavigationItemStyles = (): SystemStyleObject => {
  return {
    mt: 16,
    alignItems: 'stretch',
    ...getNavigationItemStackStyles,
  };
};

export interface NavigationItemLinks extends NavigationDrawerLinkItemProps {
  /**
   *  Title of the navigation item.
   */
  title?: string;
  /**
   *  Icon shown to the left of the title.
   */
  icon?: React.ReactElement;
  /**
   *  Nested items within the navigation item.
   */
  items?: NavigationDrawerLinkItemProps[];
  /**
   *  Some elements don't show on the desktop nav
   */
  hideOnLoggedInNav?: boolean;
}

export interface NavigationItemProps {
  /**
   *  The details of the item shown in the drawer
   */
  item: NavigationItemLinks;

  renderTitle?: (
    props: Partial<Omit<NavigationDrawerLinkItemProps, 'renderTitle'>>
  ) => React.ReactElement;

  /**
   *  If true, the item is expanded and the nested items being displayed
   */
  isNestedOpen: boolean;
  /**
   *  Callback indicating when the item was expanded or collapsed.
   */
  handleNestedToggle: () => void;
  /**
   *  Callback called when link item is clicked.
   */
  handleItemClick: () => void;
}

/**
 * An Accordion style, Drawer component that allows for nested items
 * to be shown or hidden
 */
export const NavigationItem = ({
  item,
  isNestedOpen,
  handleNestedToggle,
  handleItemClick,
}: NavigationItemProps) => {
  if (item.items && item.items.length > 0) {
    return (
      <Stack>
        <NavigationDrawerButton
          data-testid={item.id}
          title={item.title}
          onClick={handleNestedToggle}
          isOpen={isNestedOpen}
          showChevronIcon={true}
          isBoldText={false}
        />
        <Collapse in={isNestedOpen} animateOpacity>
          <VStack sx={getNavigationItemNestedStyles()}>
            {item.items.map((nestedItem) => (
              <NavigationDrawerLinkItem
                {...nestedItem}
                key={nestedItem.title}
                data-testid={nestedItem.id}
                onClick={handleItemClick}
              />
            ))}
          </VStack>
        </Collapse>
      </Stack>
    );
  }

  return <NavigationDrawerLinkItem {...item} onClick={handleItemClick} />;
};
