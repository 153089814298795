import React, { useContext } from 'react';
import { ListItem, Text, OrderedList, UnorderedList } from '@chakra-ui/react';
import { getTypographyToken } from '@allergan-data-labs/alle-elements-core';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { ILink } from '@packages/core-contentful-rest';
import { ButtonLink } from '@packages/core-components/src/components/button-link/buttonLink';
import { scrollIntoView } from '@packages/core-components/src/utils/scrollIntoView';

import {
  getGqlEntryHyperlink,
  getOrderedListStyleTypeByLevel,
  getUnorderedListStyleTypeByLevel,
  ListContext,
} from './defaultStyleHelpers';
import { CustomizeDefaultOptions, LinkEntry } from './types';
import { CmsLink } from '../cms-link/cmsLink';

// TODO: update this logic if request for link enhancement is implemented: UC-3754 + PD-5461
const typographyCss = getTypographyToken('Body/Medium/Regular');
const linkStyles = typographyCss
  ? Object.keys(typographyCss).reduce(
      (styles, key) => {
        styles[key] = 'inherit';
        return styles;
      },
      { textColor: 'inherit' } as Record<string, string>
    )
  : { textColor: 'inherit' };

const defaultRichTextRenderOptions = ({
  customRenderNodeOptions,
  customRenderTextOption,
  nodeProps,
  gqlLinks,
  onEntryHyperlinkClick,
}: CustomizeDefaultOptions): Options => {
  return {
    renderText: customRenderTextOption,
    renderMark: {
      [MARKS.SUPERSCRIPT]: (text: React.ReactNode) => <sup>{text}</sup>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children: React.ReactNode) => (
        <Text
          as="h2" // for default we don't want to have h1 because it could negatively impact SEO
          textStyle={{
            base: 'Headline/Extra large/Primary regular',
            md: 'Display/Small/Primary',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h1}
        >
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_2]: (_, children: React.ReactNode) => (
        <Text
          as="h2"
          textStyle={{
            base: 'Headline/Large/Primary regular',
            md: 'Headline/Extra large/Primary regular',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h2}
        >
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_3]: (_, children: React.ReactNode) => (
        <Text
          as="h3"
          textStyle={{
            base: 'Headline/Medium/Primary regular',
            md: 'Headline/Large/Primary regular',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h3}
        >
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_4]: (_, children: React.ReactNode) => (
        <Text
          as="h4"
          textStyle={{
            base: 'Headline/Small/Primary regular',
            md: 'Headline/Medium/Primary regular',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h4}
        >
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_5]: (_, children: React.ReactNode) => (
        <Text
          as="h5"
          textStyle={{
            base: 'Headline/Extra small/Primary regular',
            md: 'Headline/Small/Primary regular',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h5}
        >
          {children}
        </Text>
      ),
      [BLOCKS.HEADING_6]: (_, children: React.ReactNode) => (
        <Text
          as="h6"
          textStyle={{
            base: 'Body/Large/Regular',
            md: 'Headline/Extra Small/Primary regular',
          }}
          {...nodeProps?.allText}
          {...nodeProps?.allHeaders}
          {...nodeProps?.h6}
        >
          {children}
        </Text>
      ),
      [BLOCKS.PARAGRAPH]: (_, children: React.ReactNode) => (
        <Text
          textStyle="Body/Medium/Regular"
          {...nodeProps?.allText}
          {...nodeProps?.p}
        >
          {children}
        </Text>
      ),
      [INLINES.HYPERLINK]: (node, children: React.ReactNode) => {
        if (!node?.data?.uri) return;
        if (node?.data?.uri.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <ButtonLink
              variant="action"
              {...linkStyles}
              onClick={() => scrollIntoView(node?.data?.uri)}
            >
              {children}
            </ButtonLink>
          );
        }
        return (
          <CmsLink {...linkStyles} variant="action" href={node.data.uri}>
            {children}
          </CmsLink>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children: React.ReactNode) => {
        const entryLink: LinkEntry | undefined =
          (node?.data?.target as ILink)?.fields ||
          getGqlEntryHyperlink(node.data.target, gqlLinks);
        if (!entryLink?.url) return;
        if (entryLink?.url?.includes('scrollAnchor=')) {
          // This link text indicates link should scroll to some element on current page
          return (
            <ButtonLink
              variant="action"
              {...linkStyles}
              onClick={() => entryLink.url && scrollIntoView(entryLink.url)}
            >
              {children}
            </ButtonLink>
          );
        }
        return (
          <CmsLink
            {...linkStyles}
            href={entryLink.url}
            variant="action"
            onClick={
              onEntryHyperlinkClick
                ? (event) =>
                    onEntryHyperlinkClick(event, {
                      forceOpenSameTab: entryLink.forceOpenSameTab,
                    })
                : undefined
            }
          >
            {children}
          </CmsLink>
        );
      },
      [BLOCKS.UL_LIST]: (_, children: React.ReactNode) => {
        const List: React.FunctionComponent = () => {
          const { listLevel } = useContext(ListContext);
          const newListLevel = listLevel + 1;
          return (
            <ListContext.Provider value={{ listLevel: newListLevel }}>
              <UnorderedList
                styleType={getUnorderedListStyleTypeByLevel(newListLevel)}
                marginBlockStart={0}
                marginBlockEnd={0}
                textAlign={'left'}
                paddingInlineStart={0}
                sx={{
                  'li > *:not(ol):not(ul)': {
                    display: 'list-item',
                    listStylePosition: 'inside',
                  },
                }}
              >
                {children}
              </UnorderedList>
            </ListContext.Provider>
          );
        };

        return <List />;
      },
      [BLOCKS.OL_LIST]: (_, children: React.ReactNode) => {
        const List: React.FunctionComponent = () => {
          const { listLevel } = useContext(ListContext);
          const newListLevel = listLevel + 1;
          return (
            <ListContext.Provider value={{ listLevel: newListLevel }}>
              <OrderedList
                styleType={getOrderedListStyleTypeByLevel(newListLevel)}
                marginBlockStart={0}
                marginBlockEnd={0}
                textAlign={'left'}
                paddingInlineStart={0}
                sx={{
                  'li > *:not(ol):not(ul)': {
                    display: 'list-item',
                    listStylePosition: 'inside',
                  },
                }}
              >
                {children}
              </OrderedList>
            </ListContext.Provider>
          );
        };

        return <List />;
      },
      [BLOCKS.LIST_ITEM]: (_, children: React.ReactNode) => {
        return (
          <ListItem
            // to display the li indicator same size as text, display li child as list-item and display list item as 'contents'
            display={'contents'}
          >
            {children}
          </ListItem>
        );
      },
      ...customRenderNodeOptions,
    },
  };
};

export { defaultRichTextRenderOptions, linkStyles };
