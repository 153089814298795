import { TierAListTypes } from './alistTypes';
import { currentYear } from './alistHelpers';
import { formatNumberWithCommas } from '../../../../../helpers/format';

export const gradients = {
  gold: `background: linear-gradient(
    73.47deg,
    #836428 -19.07%,
    #F8D98B 51.7%,
    #836428 93.03%
  ),
  linear-gradient(
    0deg,
    #00000040,
    #00000040
  ),
  #C08676;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;`,
  nude: 'color: #C08676;',
};

export const AlistPerksContent = {
  title: 'Your Allē member perks',
  alistTitle: 'Your A-List perks',
  secondTitle: 'A-List perks',
  secondAlistTitle: 'Always-on Allē member perks',
  textColor: '#090909',
  alistTextColor: '#FFFFFF',
  titleColor: 'linear-gradient(to top right, #836428, #F8D98B, #836428)',
  perks: [
    'Redeem points for savings on any Allē brand.',
    'Score surprise offers when you check in with Allē Flash.',
    'Earn rewards for referring friends.',
    'Get exclusive access to annual saving events such as BOTOX® Cosmetic Day.',
    'Rack up points with double point events.',
  ],
  alistPerksMember: [
    'Points don’t expire while you’re an A-Lister.',
    'Exclusive offers and surprise gifts.',
    "Get access to the A-Line, Allē's VIP Member support.",
    'Be the first to know about offers and events.',
  ],
  alistPerksAList: [
    'Points don’t expire while you’re an A-Lister.',
    'Exclusive offers and surprise gifts.',
    "Get access to the A-Line, Allē's VIP Member support.",
    'Be the first to know about offers and events.',
  ],
};

export const AlistTierContent = (
  type: TierAListTypes,
  tierThreshold?: number | null
) => {
  const listStatus = {
    MEMBER: {
      title: 'A-List progress',
      message:
        'Unlock VIP benefits like early access, surprise gifts, and more.',
      showBadge: false,
      path: `#C08676`,
      theme: gradients.nude,
      trail: '#C0867633',
    },
    NEW_A_LISTER: {
      title: 'Congrats! You’re on the A-List.',
      message: `You have access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
    A_LISTER: {
      title: `${currentYear} A-List progress`,
      message: `Earn ${formatNumberWithCommas(
        tierThreshold ?? 1200
      )} points this calendar year to keep your A-List status.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
    RETURNING_A_LISTER: {
      title: 'Another year on the A-List!',
      message: `Enjoy continued access to all the best A-List perks through ${
        currentYear + 1
      }.`,
      showBadge: true,
      path: '#F8D98B',
      theme: gradients.gold,
    },
  };
  return listStatus[type] ?? listStatus.MEMBER;
};
